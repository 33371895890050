import React from "react";
import { Suspense } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { CircularProgress } from "@mui/material";
import AppDownloadButton from "../AppDownloadButton";
import RulesGameInfo from "../RulesGameInfo";
import useTranslation from "../../helpers/translationTexts/useTranslation";
import { InstallAppEvents } from "../../constants/analyticEvents";
import { Content } from "@/types/PropTypes";
import ReactMarkdown from "react-markdown";
import Container from "../Container";
import styles from "./HomeContent.module.scss";

const Game = dynamic(() => import("../Game"), { suspense: true });
const HomeContent = ({ content }: Content) => {
  const sudokuPuzzleGameText = useTranslation("sudokuPuzzleGame");
  const omisoftText = useTranslation("omisoft");
  const playBestText = useTranslation("playBest");
  const playOnlineText = useTranslation("online");
  const playSudokuText = useTranslation("playSudoku");
  const nowDownloadText = useTranslation("nowDownload");
  const gameRulesText = useTranslation("gameRules");
  const seeWeToldYouText = useTranslation("seeWeToldYou");
  const dontWasteYourTimeText = useTranslation("dontWasteYourTime");
  const appIsSudokuProText = useTranslation("appIsSudokuPro");

  return (
    <>
      <div className={styles.gameContainer}>
        <Suspense
          fallback={
            <div className={styles.loaderContainer}>
              <CircularProgress size={70} />
            </div>
          }
        >
          <Game />
        </Suspense>
      </div>
      <Container>
        <div className={styles.mobileAppWrapper}>
          <Image
            className={styles.mobileAppImage}
            src="/images/main@desktop.webp"
            alt="mobile preview"
            width={271}
            height={331}
          />
          <div className={styles.mobileAppBox}>
            <Image src="/images/logo.webp" alt="logo of app" width={90} height={90} />
            <div className={styles.mobileAppInfoBox}>
              <h2 className={styles.mobileAppRatingTitle}>{sudokuPuzzleGameText}</h2>
              <h3 className={styles.mobileAppRatingSubitle}>{omisoftText}</h3>
              <Image src="/images/rating.webp" alt="rating of app" width={93} height={16} />
            </div>
          </div>
          <div className={styles.marketLinkBox}>
            <AppDownloadButton market="google" action={InstallAppEvents.installAppHome2} />
            <AppDownloadButton market="apple" action={InstallAppEvents.installAppHome2} />
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </Container>
      <div className={styles.playMainWrapper}>
        <div className={styles.blueLine}>
          <div className={styles.playBestWrapper}>
            <div className={styles.playBestBox}>
              <p className={styles.playBestText}>{playBestText}</p>
              <p className={styles.playOnlineText}>{playOnlineText}</p>
            </div>
            <p className={styles.playSudokuText}>{playSudokuText}</p>
          </div>

          <p className={styles.downLoadText}>{nowDownloadText}</p>
          <Image
            className={styles.playSudokuImg}
            src="/images/main@desktop.webp"
            alt="main image"
            width={340}
            height={416}
          />
          <p className={styles.downLoadTextSecond}>{nowDownloadText}</p>
          <div className={styles.marketLinkWrapper}>
            <AppDownloadButton market="google" action={InstallAppEvents.installAppHome2} />
            <AppDownloadButton market="apple" action={InstallAppEvents.installAppHome2} />
          </div>
        </div>
      </div>

      <Container>
        <RulesGameInfo content={gameRulesText} />
        <div className={styles.rulesFooterWrapper}>
          <h2 className={styles.rulesFooterSubTitle}>{seeWeToldYouText}</h2>
          <p className={styles.rulesFooterSubText}>
            {dontWasteYourTimeText}
            <br />
            {appIsSudokuProText}
          </p>
          <Image src="/images/logo.webp" alt="logo of app" width={112} height={112} />
          <h2 className={styles.ratingTitle}>{sudokuPuzzleGameText}</h2>
          <h3 className={styles.ratingSubtitle}>{omisoftText}</h3>
          <Image className={styles.ratingImg} src="/images/rating.webp" alt="rating of app" width={190} height={32} />
        </div>
      </Container>
      <div className={styles.ratingLinkBox}>
        <AppDownloadButton market="google" action={InstallAppEvents.installAppHome3} />
        <AppDownloadButton market="apple" action={InstallAppEvents.installAppHome3} />
      </div>
    </>
  );
};

export default HomeContent;
