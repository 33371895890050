import React from "react";
import { InstallAppEvents } from "../../constants/analyticEvents";
import { AnalyticsHelper } from "../../utils/analyticEvents";
import Image from "next/image";
import styles from "./AppDownloadButton.module.scss";

type Props = {
  market: string;
  action?: InstallAppEvents;
};

const AppDownloadButton: React.FC<Props> = ({ market, action }) => {
  const link =
    market === "apple"
      ? "https://apps.apple.com/ua/app/sudoku-pro-play-offline/id1661946513"
      : "google"
      ? "https://play.google.com/store/apps/details?id=com.omiplay.sudokupro"
      : "";

  const onClickHandler = () => {
    AnalyticsHelper({ action: InstallAppEvents.installMobApp });
    if (action) {
      AnalyticsHelper({ action: action });
    }

    if (market === "apple") {
      AnalyticsHelper({ action: InstallAppEvents.installMobAppIos });
    } else if (market === "google") {
      AnalyticsHelper({ action: InstallAppEvents.installMobAppAndroid });
    }
  };

  return (
    <a onClick={onClickHandler} href={link} target="_blank" rel="nofollow noopener noreferrer">
      <Image
        className={styles.imgBtn}
        src={`/images/${market}-store@desktop.webp`}
        alt={`${market} market`}
        width={190}
        height={55}
      />
    </a>
  );
};

export default AppDownloadButton;
