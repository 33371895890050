import React from "react";
import Image from "next/image";
import { TContent } from "../../types/popup";
import styles from "./RulesGameInfo.module.scss";

const RulesGameInfo: React.FC<TContent> = ({ content }) => {
  const list = content?.list?.split("<list>");
  return (
    <div className={styles.rulesWrapperContent}>
      <h3 className={styles.titleRule}>{content?.subtitle}</h3>
      <h2 className={styles.subTitleRule}>{content?.title}</h2>
      <p className={styles.textRule}>{content?.titleText}</p>
      <h2 className={styles.titleUp}>{content?.goal}</h2>
      <p className={styles.textRule}>{content?.goalText}</p>
      <h2 className={styles.titleUp}>{content?.ruleTitle}</h2>
      <p className={styles.textRule}>{content?.ruleText}</p>
      <h2 className={styles.titleUp}>{content?.fail}</h2>
      <ul className={styles.listRule}>
        {list.map((text: string, i: number) => {
          return (
            <li className={styles.listItem} key={`${text.slice(-8).trim()}${i}`}>
              <Image className={styles.listDots} src="/images/ellipse.svg" alt="blue dot" width={14} height={14} />
              <p className={styles.listItemText}>{text}</p>
            </li>
          );
        })}
      </ul>
      <div className={styles.coloredBox}>
        <h2 className={styles.cautionTitle}>{content?.remember}</h2>
        <p className={styles.cautionText}>{content?.rememberText}</p>
      </div>
      <Image className={styles.cautionImage} src="/images/last_desktop.webp" alt="rules img" width={380} height={412} />
    </div>
  );
};

export default RulesGameInfo;
